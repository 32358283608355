import { createSlice } from '@reduxjs/toolkit';
import { HelpProp } from './types';

const initialStateValue: HelpProp = {
    HelpPropType :{
        total: 0,
        per_page: 10,
        current_page: 0,
        last_page: 0,
        first_page: 0,
        first_page_url: '',
        last_page_url: '',
        next_page_url: '',
        previous_page_url: '',
        data: [],
    },
    helpLoading:true,
    detailedHelpLoading:true,
    DetailedHelpType:{
      id: '',
      user_name: '',
      user_id: '',
      issue: '',
      order_tatus: '',
      note: '',
      case_id: '',
      complaintType: '',
      createdAt: '',
      updatedAt: '',
      user: {
        kyc_status: '',
        first_name: '',
        last_name: ''
      }
    }
};

const helpSlice = createSlice({
  name: 'help',
  initialState: {value: initialStateValue},
  reducers: {
    setHelpData(state, action) {
      state.value= { ...state.value, ...action.payload };
    },
    resetHelpState(state) {
      state.value =  initialStateValue;
    },
  },
});

export const { setHelpData, resetHelpState } = helpSlice.actions;

export default helpSlice.reducer;
