/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { BusinessSetupPropType } from "./types";

const initialStateValue: BusinessSetupPropType = {
  businessSettings: {
    id: 0,
    country_id: 0,
    state_id: 0,
    service_fee: 0,
    commission: 0,
    surge_fee: 0,
    minimum_delivery_cost: 0,
    minimum_order: 0,
    minimum_delivery_percentage: 0,
    delivery_threshold: 0,
    delivery_cost_per_mile: 0,
    maximum_order_amount: 0,
    free_delivery_amount: 0,
    minimum_service_fee: 0,
  },
  businessSettingsLoading: false,
  businessSettingsList:{
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data:[]
  },
  cancellation: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data: [],
  },
  cancellationLoading: false,
  vehicle: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data: [],
  },
  vehicleModel: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data: [],
  },
  vehicleModelLoading: true,
  vehicleLoading: true,
  ridetypeLoading: true,
  ridetype: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data: [],
  },
  tabletLoading: true,
  tablet: {
    id: 0,
    tab_name: "",
    tab_description: "",
    tab_image: "",
    memory_size: 0,
    storage_size: 0,
    battery_size: 0,
    camera_size: 0,
    price: 0,
    is_available: 0,
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data: [],
  },
};

export const businessSetupSlice = createSlice({
  name: "businesssetup",
  initialState: { value: initialStateValue },
  reducers: {
    setBusinessSetup: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetBusinessSetup: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setBusinessSetup, resetBusinessSetup } =
  businessSetupSlice.actions;

export default businessSetupSlice.reducer;
